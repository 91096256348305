export default {
  LOGIN: {
    ADMIN: "/admin/login",
  },
  LOGOUT: {
    LOGOUT_ALL: "/admin/logout/all",
  },
  AUTH: {
    FORGOT_PASSWORD: "/admin/forgot/password",
    RESET_PASSWORD: "/common/reset/password",
  },
  PROFILE: "/admin/profile",
  UPLOAD_FILE: "common/upload/files",

  DASHBOARD: {
    ADMIN: "/admin/dashboard",
  },

  DROPDOWN: {
    USER: "/admin/dropdown/users",
  },

  COMMON: {
    ADDRESS_API: "/common/address/api",
  },

  AGENT: {
    CREATE: "/admin/create/agent",
    LISTING: "/admin/get/agents",
    DETAILS: "/admin/get/agent",
    UPDATE: "/admin/update/agent",
    ATTENDANCE_LIST: "/admin/get/agent/attendance/list",
    ATTENDANCE_SLOT: "/admin/get/agent/attendance/slots",
    DELETE: "/admin/delete/agent",
    BULK_DELETE: "/admin/delete/all/agents",
    RESET_PASSWORD: "/admin/agent/reset/email",
  },
  SUPERVISOR: {
    CREATE: "/admin/create/supervisor",
    LISTING: "/admin/get/supervisors",
    DETAILS: "/admin/get/supervisor",
    UPDATE: "/admin/update/supervisor",
    ATTENDANCE_LIST: "/admin/get/supervisor/attendance/list",
    ATTENDANCE_SLOT: "/admin/get/supervisor/attendance/slots",
    RESET_PASSWORD: "/admin/supervisor/reset/email",
    TRANSFER_ACCOUNT: "/admin/transfer/supervisor/acc",
  },
  MANAGER: {
    CREATE: "/admin/create/manager",
    LISTING: "/admin/get/managers",
    DETAILS: "/admin/get/manager",
    UPDATE: "/admin/update/manager",
    RESET_PASSWORD: "/admin/manager/reset/email",
  },
  SUB_ADMIN: {
    CREATE: "/admin/create/sub/admin",
    LISTING: "/admin/get/sub/admins",
    DETAILS: "/admin/get/sub/admin",
    UPDATE: "/admin/update/sub/admin",
    PARENT:"/admin/get/profile/sub/admin"

  },
  ADMIN: {
    CREATE: "/admin/create/admin",
    LISTING: "/admin/get/admins",
    DETAILS: "/admin/get/admin",
    UPDATE: "/admin/update/admin",
    PARENT:"/admin/get/profile/admin"

  },
  SUPERADMIN: {
    CREATE: "/admin/create/super/admin",
    LISTING: "/admin/get/super/admins",
    DETAILS: "/admin/get/super/admin",
    UPDATE: "/admin/update/super/admin",
    PARENT:"/admin/get/profile/super/admin"
  },
  REPORT: {
    AGENT: "/admin/agent/report",
    SUPERVISOR: "/admin/supervisor/report",
    MANAGER: "/admin/manager/report",
    SUB_ADMIN: "/admin/sub/admin/report",
    ATTENDANCE: "/admin/attendance/report",
  },
  ATTENDANCE: {
    ATTENDANCE: "/admin/attendance/list",
  },
  SUPERVISOR_ATTENDANCE: {
    SUPERVISOR_ATTENDANCE: "/admin/supervisor-attendance/list",
  },
  NOTIFICATION: {
    SEND_NOTIFY: "/admin/send/notify",
    GET_NOTIFY: "/admin/get/notify",
  },
  CMS: {
    DETAILS: "/admin/get/cms",
    UPDATE: "/admin/update/cms",
  },
  CONFIG: {
    DETAILS: "/admin/get/config",
    UPDATE: "/admin/update/config",
  },
  CSC: {
    COUNTRIES: "/admin/countries",
    STATES: "/admin/states",
    CITIES: "/admin/cities",
  },
};
